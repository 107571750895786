














































import Vue from "vue";
import { Event, EventCardStatus, EventCardActions } from "@/types";
import EventCardStartEnd from "@/components/EventCardStartEnd.vue";
import EventCardChips from "@/components/EventCardChips.vue";
import EventCardBtns from "@/components/EventCardBtns.vue";
import EventCardPoints from "@/components/EventCardPoints.vue";
import { addHours } from "date-fns";

export default Vue.extend({
  props: {
    event: {
      type: Object as () => Event
    },
    fullView: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    status(): Record<EventCardStatus, boolean> {
      return {
        closed: !this.event.isActive,
        enrolling: this.event.approved === null,
        applied: this.event.approved === 0,
        confirmed: this.event.approved === 1 && this.event.attended !== 1,
        examFailed:
          this.event.attended === 1 &&
          (this.event.examStatus === "failed" ||
            this.event.examStatus === "none"),
        awarded: false,
        showQr:
          this.event.qrUrl !== "" &&
          this.event.type === "offline" &&
          this.event.approved === 1 &&
          new Date().getTime() < addHours(this.event.end, 1).getTime() &&
          this.event.attended !== 1
      };
    },

    actions(): Record<EventCardActions, boolean> {
      const now = new Date();
      return {
        enroll:
          this.event.approved !== 1 &&
          now.getTime() < this.event.end.getTime() &&
          this.event.isActive &&
          !this.event.attended,
        unenroll:
          this.event.approved === 0 ||
          (this.event.approved === 1 &&
            new Date().getTime() < this.event.start.getTime()),
        qrcode:
          this.event.approved === 1 &&
          this.event.type === "offline" &&
          new Date().getTime() < addHours(this.event.end, 1).getTime() &&
          this.event.attended !== 1 &&
          !this.fullView,
        exam:
          this.event.attended === 1 &&
          this.event.examStatus !== null &&
          this.event.examStatus !== "passed",
        feedback: this.event.feedbackStatus === "none",
        join:
          this.event.approved === 1 &&
          this.event.type !== "offline" &&
          new Date().getTime() < addHours(this.event.end, 1).getTime() &&
          this.event.attended !== 1 &&
          this.event.joinUrl !== null
      };
    }
  },

  components: {
    EventCardStartEnd,
    EventCardChips,
    EventCardBtns,
    EventCardPoints
  },

  methods: {
    openInANewTab(url: string | undefined) {
      if (!url) {
        return;
      }

      const newTab = window.open(url, "_blank");

      if (newTab) {
        newTab.focus();
      }
    }
  }
});
