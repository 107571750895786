import { render, staticRenderFns } from "./PointCard.vue?vue&type=template&id=475821aa&scoped=true&"
import script from "./PointCard.vue?vue&type=script&lang=ts&"
export * from "./PointCard.vue?vue&type=script&lang=ts&"
import style0 from "./PointCard.vue?vue&type=style&index=0&id=475821aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475821aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDivider,VIcon})
